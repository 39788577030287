<template>
  <div class="chat-container flex flex-col mt-2">
    <div
      class="chat-header flex items-center justify-end"
      v-if="user"
    >
      <div class="text-right">
        <h1 class="text-carrotOrange font-black text-lg">
          Okeke Timothy
        </h1>
        <p class="uppercase">
          General Manager, Operations
        </p>
      </div>
      <img
        src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        class="chat-img-user"
      >
    </div>
    <div
      class="chat-header flex items-center"
      v-else
    >
      <img
        src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        class="chat-img"
      >
      <div>
        <h1 class="text-darkPurple font-black text-lg">
          Okeke Timothy
        </h1>
        <p class="uppercase">
          General Manager, Operations
        </p>
      </div>
    </div>

    <div :class="chatBody">
      Some of your KPAs are not accurate.
    </div>
    <div
      class="chat-footer flex justify-between"
      v-if="user"
    >
      <p class="text-romanSilver">
        09:00AM
      </p>
      <icon
        icon-name="icon-trash"
        size="xsm"
      />
    </div>
    <div
      class="chat-footer flex"
      v-else
    >
      <p class="text-romanSilver">
        09:00AM
      </p> <p class="mx-2 text-mediumSeaGreen">
        *
      </p>
      <p class="uppercase text-mediumSeaGreen">
        Read
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['user'],
  computed: {
    chatBody() {
      return this.user ? "chat-body-user" : "chat-body text-darkPurple";
    },
  },

};
</script>

<style scoped>
.chat-img{
      height: 40px;
    margin-right: 12px;
    width: 40px;
    object-fit: cover;
    box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
    border-radius: 5px;
}
.chat-img-user{
      height: 40px;
    margin-left: 12px;
    width: 40px;
    object-fit: cover;
    box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
    border-radius: 5px;
}
.chat-body{
background: #FFFFFF;
border: 1px solid #878E99;
box-sizing: border-box;
border-radius: 5px;
flex: none;
align-self: stretch;
flex-grow: 0;
margin: 8px 0px;
padding: 15px;
}
.chat-body-user{
background: rgba(244, 213, 176, 0.25);
border-radius: 5px;
align-self: stretch;
flex-grow: 0;
margin: 8px 0px;
padding: 15px;
}
</style>
